<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card>
      <v-card-title class="display-2 mb-4">
        Join a Team
      </v-card-title>

      <v-card-text>
        Select the team you'd like to join. Please keep in mind only activities tracked after joining the team will count towards team rankings.
      </v-card-text>

      <v-card-text>
        <v-autocomplete 
          v-model="selectedGroupId"
          :items="groups"
          item-value="id"
          item-text="name"
          placeholder="Select your team"
          />
      </v-card-text>

      <v-card-text>
        <v-btn large color="primary" :disabled="!selectedGroupId" :loading="$store.getters.isLoading" @click="submit">Join Team</v-btn>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import groupService from "@/services/groupService";
import eventService from "@/services/eventService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'

import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    MultiStepDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      selectedGroupId: null,
      groups: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData() {
      this.groups = (await groupService.list('TENANT_TEAM')).data.data;
    },

    async submit() {
      if (this.selectedGroupId) {
        const response = (await profileService.joinGroup(this.selectedGroupId)).data;
        this.$helpers.toastResponse(this, response, 'Successfully joined the team.');
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileEdit" });
        }
      }
    },

    async getProfile() {
      if (this.user) {
        await this.loadData();
      }
    },
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    breadcrumbItems() {
      return !this.group || !this.event ? [] : [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
.join-url {
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  border: dotted 3px #ccc;
  margin-bottom: 10px;
}
.join-code {
  font-size: 3em;
  letter-spacing: 1em;
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  text-transform: uppercase;
  border: dotted 3px #ccc;
}
</style>

